/* global toastr */

import { BaseView, baseStateToProps } from '../lib/base_view'

import classNames from 'classnames'
import { connect } from 'react-redux'
import { Link, Navigate } from 'react-router-dom'
import AttrInput from '../lib/attr_input'
import React from 'react'
import { request } from '../lib/utils'

export class PasswordResetView extends BaseView {
    constructor(props) {
        super(props)
        this.auth_required = false
        this.formChange = this.formChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)

        this.state = {
            form_data: {},
            restore_success: false,
        }
    }

    formChange(name, value) {
        var form_data = this.state.form_data
        form_data[name] = value

        this.setState({
            form_data: form_data,
            form_valid: form_data.email && form_data.password,
        })
    }

    onSubmit() {
        var view = this
        const email = this.state.form_data.email

        request({
            method: 'post',
            url: '/api/auth/restore_password',
            data: {email: email},
            success: (data) => {
                toastr.success(`Проверьте почту ${email}`)
                view.setState({restore_success: true})
            }
        })
    }

    render() {
        if (this.authorized) {
            return <Navigate to="/" replace={true} state={null} />
        }

        if (this.state.restore_success === true) {
            return <Navigate to="/" replace={true}/>
        }

        const form_data = this.state.form_data

        const btn_class = classNames(
            'btn btn-primary block full-width m-b',
            { disabled: !form_data.email } // TODO: проверить регуляркой почту
        )

        return (
            <div className="container-xxl">
              <div className="authentication-wrapper authentication-basic container-p-y">
                <div className="authentication-inner py-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="app-brand justify-content-center mb-4 mt-2">
                        <a href="index.html" className="app-brand-link gap-2">
                          <span className="app-brand-logo demo">
                          <i className="ti ti-planet"></i>
                            </span>
                          <span className="app-brand-text demo text-body fw-bold ms-1">Восстановление пароля</span>
                        </a>
                      </div>
                      <p className="mb-4">Введите ваш email и мы вышлем ссылку на восстановление</p>
                        <div className="mb-3 fv-plugins-icon-container">
                          <label for="email" className="form-label">Email</label>
                          <AttrInput
                                data_type="string"
                                name="email"
                                placeholder="Email"
                                onChange={this.formChange}
                                value={form_data.email}
                            />
                        </div>
                        <div className="mb-3">
                          <button className="btn btn-primary d-grid w-100 waves-effect waves-light" onClick={this.onSubmit}>
                            Вперед
                          </button>
                        </div>
                      <div className="divider my-2">
                        <div className="divider-text">или</div>
                      </div>
                      <div className="d-flex justify-content-center">
                        <Link className="me-1" to="/">Домой</Link> | <Link className="ms-1" to="/auth/login">Вход</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
    }
}

export default connect(baseStateToProps)(PasswordResetView)
