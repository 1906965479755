
export const initialState = {
    authorized: !!localStorage.getItem('auth_access_token'),
    user: null,
}

export function builderCallback(builder) {
    builder
        .addCase('auth_clean', (state) => {
            state = {
                authorized: false,
                user: null,
            }
            return state
        })

        .addCase('auth_authenticated', (state, action) => {
            // Запомним информацию о токенах аутентификации и как их обновлять
            localStorage.setItem('auth_access_token', action.data.access_token)
            localStorage.setItem('auth_refresh_token', action.data.refresh_token)
            localStorage.setItem('auth_refresh_url', action.data.refresh_url)

            // Пользователь аутентифицирован
            state.authorized = true

            return state
        })

        .addCase('auth_data', (state, action) => {
            state.user = action.data
            return state
        })

        .addDefaultCase((state, action) => {
            return state
        })
}
