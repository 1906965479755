import LoginView from '../smart_bps/auth/login'
import ChangePasswordView from '../smart_bps/auth/change_password'

import PasswordResetView from '../smart_bps/auth/restore_password'
import PasswordResetCompleteView from '../smart_bps/auth/restore_password_complete'

import { builderCallback, initialState } from '../smart_bps/auth/index'

import React from 'react'
import { Route } from 'react-router'
import { createReducer } from '@reduxjs/toolkit'

export const reducer = createReducer(initialState, (builder) => {
    builderCallback(builder)
})

export const routes = (
    <Route path="auth">
        <Route exact path="login" element={<LoginView />} />
        <Route exact path="change_password" element={<ChangePasswordView/>}/>
        <Route exact path="restore_password" element={<PasswordResetView />} />
        <Route exact path="restore_password_complete/:token" element={<PasswordResetCompleteView />} />
    </Route>
)
