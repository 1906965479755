/* global $ */

import React, {Component} from 'react';
import AttrInput from '../lib/attr_input'
import {split_list} from '../lib/utils'

/**
* Модальное окно поиска документов.
* В параметрах требует
* - schema: Схема формы (разделение параметров по группам)
* - data: Данные для инициализации формы поиска
* - choices: Все choces для полей формы
* - onSearch: callback
*/
export class ModalSearch extends Component {

    constructor(props) {
        super(props)

        this.onSearch = this.onSearch.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onClean = this.onClean.bind(this)

        this.state = {data: props.data || {}}
    }

    onChange(name, value) {
        this.setState({data: { ...this.state.data, [name]: value}});
    }

    onClean() {
        this.props.onSearch({})
        this.setState({data: {}})
        $('#modal_search').modal('hide')
    }

    onSearch() {
        this.props.onSearch(this.state.data)
        $('#modal_search').modal('hide')
    }

    render() {
        const view = this;
        const data = this.state.data

        function render_attr(attr) {
            return <AttrInput key={attr.name}
                              data_type={attr.data_type}
                              name={attr.name}
                              title={attr.title}
                              value={data[attr.name]}
                              choice_name={attr.choice_name}
                              choices={view.props.choices}
                              inner_attrs={attr.inner_attrs}
                              access="edit"
                              onChange={view.onChange}/>
        }

        function renderGroup(item, idx) {
            let group_title = item[0]
            let attrs = item[1]

            return <div key={idx}>
                {group_title && <div className="divider my-2">
                    <div className="divider-text"><strong>{group_title}</strong></div>
                </div>}
                <div className="row">
                    {split_list(attrs, 3).map((attrs, idx1) => {
                        return <div className="col-md-4" key={idx1}>
                            {attrs.map(render_attr)}
                        </div>
                    })}
                </div>
            </div>
        }

        return (
          <div className="modal fade" id="modal_search" tabIndex="-1" style={{display: "none"}} aria-hidden="true">
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel1">Поиск по параметрам</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    {view.props.schema.map(renderGroup)}
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary p-2 me-1" onClick={this.onSearch}>
                        <span className="ti ti-search me-1"></span>
                        Найти
                    </button>
                    <button type="button" className="btn btn-warning p-2 me-1" onClick={this.onClean}>
                        <span className="ti ti-x me-1"></span>
                        Сброс
                    </button>
                    <button type="button" className="btn btn-secondary p-2 me-1" data-bs-dismiss="modal">
                        Закрыть
                    </button>
                </div>
              </div>
            </div>
          </div>
        )
    }
}
