/* global $ */

// import axios from 'axios'
import { format_datetime } from '../lib/utils'
import React, { Component } from 'react'
import { uploadFile, request } from '../lib/utils'
import fileDownload from 'js-file-download'


/**
 * Просмотрщик файла, который поддерживает
 * - Растровые изображения
 * - pdf (через pdf-js)
 * - Офисные документы через сервис OnlyOffice
 * Принимает props
 * - file: Объект
 */
class FileViewer extends Component {

  render() {
    const file = this.props.file
    let show_container;

    if (file.view_mode === "img") {
      show_container = <img src={file.url} style={{ maxWidth: "100%" }}></img>
    }

    else if (file.view_mode === "pdf") {
      show_container = <iframe src={file.url} height="800" width="100%" />
    }

    // TODO: Нужно сделать
    // else if (file.view_mode === "office") {
    //     show_container = <img src={file.url} style={{ maxWidth: "100%" }}></img>
    // }

    else {
      show_container = <div>Просмотр недоступен для файла {file.view_mode}</div>
    }

    return <div>
      {show_container}
      <div>
        <div>Дата загрузки: {format_datetime(file.d_create)}</div>
        <div>Название файла: {file.file_name}</div>
      </div>
    </div>
  }
}

/**
 * Отображение файлов
 * props:
 * - project_name
 * - object_id
 * - files_schema
 * - files
 */
export class FileListBlock extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showTypes: [],
    }

    this.ref_upload_file = React.createRef();
    this.uploadHandler = this.uploadHandler.bind(this);
    this.getFilesByType = this.getFilesByType.bind(this);
    this.renderSchemaItem = this.renderSchemaItem.bind(this);
    this.toggleFilesInType = this.toggleFilesInType.bind(this);
    this.renderFile = this.renderFile.bind(this);
  }

  // Фильтрует из всех файлов с нужным типом и возвращает список из них
  getFilesByType(filetype) {
    let files = []

    this.props.files.forEach(element => {
      if (element.file_type == filetype) {
        files.push(element)
      }
    });

    return files
  }

  downloadFile(item) {
    request({
      method: "GET",
      url: item.url,
      responseType: "blob",
      success: (content) => { fileDownload(content, item.file_name) },
    })
  }

  // Переключаем отображение файлов в типе
  toggleFilesInType(filetype) {
    const showTypes = this.state.showTypes
    let showTypesNew = [...showTypes]
    const index = showTypesNew.indexOf(filetype)

    if (index === -1) {
      showTypesNew.push(filetype)
    }
    else {
      showTypesNew.splice(index, 1)
    }

    this.setState({ showTypes: showTypesNew })
  }

  // Отрисовать модальное окно для отображения файла
  renderShowModal() {
    // Файл не выбран
    if (this.state === null || this.state.selected_file === undefined) {
      return null;
    }

    const file = this.state.selected_file;

    return (
      <div className="modal fade" id="modal_file" tabIndex="-1" style={{ display: "none" }} aria-hidden="true">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel1">{file.file_name}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <FileViewer file={file} />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary p-2 me-1" data-bs-dismiss="modal">
                <span className="ti ti-square-rounded-x me-1"></span>
                Закрыть
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  uploadHandler(file_type) {
    const that = this;
    const input = this.ref_upload_file.current

    function filesSelected() {
      Array.from(input.files).forEach(file_item => {
        uploadFile({
          file_item: file_item,
          url: `/api/${that.props.project_name}/${that.props.object_id}/upload_file`,
          form_args: { file_type: file_type },
          callback: (data) => { that.props.fileUploaded(data) }
        })
      })
    }

    input.addEventListener('change', filesSelected, { once: true })
    input.click()
  }

  renderSchemaItem(item) {
    const that = this

    // Заголовок (если filetype нет)
    if (item.filetype === undefined) {
      return (
        <li className="list-group-item p-1 px-3" key={item.title}>
          <strong>{item.title}</strong>
        </li>
      )
    }

    const file_list = this.getFilesByType(item.filetype)
    const files_show = that.state.showTypes.includes(item.filetype)

    return (
      <li className="list-group-item p-1 px-3" key={item.title}>
        <div className="d-flex justify-content-between">
          <div className="flex-grow-1 pointer" onClick={() => { this.toggleFilesInType(item.filetype) }}>{item.title}</div>
          <div className="ti ti-cloud-upload me-1 pointer" onClick={() => { that.uploadHandler(item.filetype) }}></div>
          <span className="badge bg-secondary rounded-pill px-2">{file_list.length}</span>
        </div>
        {files_show && <div className="d-flex flex-wrap">
          {file_list.map(this.renderFile)}
        </div>}
      </li>
    )
  }

  renderFile(item) {
    const that = this

    return <div key={item.id} className="border me-1 mb-1 p-1">
      <div>
        {item.thumbnail.includes("dummy") ? (
          <div style={{ width: "100px", height: "100px", padding: "25px" }} key={item.id}>
            <span className="ti ti-file" style={{ fontSize: "50px" }}></span>
          </div>
        ) : (
          <img src={"/media/" + item.thumbnail}
            className="rounded"
            style={{ width: "100px", height: "100px", }}
            title={item.file_name} key={item.id}></img>
        )}
      </div>
      <i className="fa fa-download me-1 pointer" key="download" onClick={() => { that.downloadFile(item) }}></i>
      <i className="fa fa-search me-1 pointer" key="search" onClick={() => {
        that.setState({ selected_file: item }, () => {
          $("#modal_file").modal("show")
        })
      }}></i>
    </div>
  }

  render() {
    return (
      <div className="card mb-3" key="files">
        <div className="card-body p-3 pb-2">
          <div className="card-title header-elements mb-0">
            <h5 className="m-0 me-2">Файлы</h5>
          </div>
        </div>
        <ul className="list-group list-group-flush">
          {this.props.files_schema.map(this.renderSchemaItem)}
        </ul>
        {this.renderShowModal()}
        <div style={{ display: "none" }}>
          <input id="file-input" type="file" multiple="multiple" ref={this.ref_upload_file}></input>
        </div>
      </div>
    )
  }
}
