/* global toastr */

import { BaseListView } from '../smart_bps/bps/list_view'
import { stateToProps } from '../smart_bps/bps/base_view'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { request, wrapView } from '../smart_bps/lib/utils'
import { Paginator } from '../smart_bps/lib/components'
import {format_datetime} from '../smart_bps/lib/utils'


class ListView extends BaseListView {
    constructor(props) {
        super(props)
        this.renderDocument = this.renderDocument.bind(this)
        this.setDocumentAction = this.setDocumentAction.bind(this)
    }

    setDocumentAction(state_to, document_id) {
        const that = this

        request({
            method: 'post',
            url: "/api/log/set_action",
            data: {
                filter_data: this.props.search_data || {},
                preset: this.props.search_preset || "",
                action: state_to,
                document_id: document_id,
            },
            success: (data) => {
                that.updateItems(that.props.list_page)
                toastr.success('Обработано событий: ' + data.cnt, 'Успешно')
            },
        })
    }

    /**
     * Отображаем карточку лога для списка в виде блока
     */
    renderDocument(item) {
      const that = this

      const btn_class = classNames("btn btn-xs", {
          'INFO': 'btn-info',
          'DEBUG': 'btn-info',
          'WARNING': 'btn-warning',
          'ERROR': 'btn-danger',
          'CRITICAL': 'btn-danger',
      }[item.log_level])

      return (
        <div className="card mb-3" key={item.id}>
          <div className="card-body p-2">
            <div className=''>
              <div className="btn-group me-2" role="group">
                <button className={btn_class}>{item.message_count}</button>
                
                <span className="btn btn-secondary btn-xs" title="Выполнена" onClick={() => {
                  that.setDocumentAction('archive', item.id)
                }}>
                    <i className='ti ti-xs ti-check'></i>
                </span>
                <span className="btn btn-secondary btn-xs" title="Игнорировать" onClick={() => {
                  that.setDocumentAction('ignore', item.id)
                }}>
                <i className='ti ti-xs ti-eye-off'></i>
                </span>
                <span className="btn btn-secondary btn-xs" title="Хочу на ручки" onClick={() => {
                    that.setDocumentAction('to_self', item.id)
                }}>
                  <i className='ti ti-xs ti-bug'></i>
                </span>
              </div>

              <span className='me-3'>
                <span className='ti ti-calendar ti-xs me-1'></span>
                {format_datetime(item.d_last_message)}
              </span>
              <span className='me-3'>
                <span className='ti ti-settings ti-xs me-1'></span>
                {item.app_name} ({item.run_mode})
              </span> 
              <span className='me-3'>
                <span className='ti ti-send ti-xs me-1'></span>
                {this.getChoice("bps_log_state", item.state) || '---'}
              </span> 
              
              {item.responsible_id && <span className='me-3'>
                <span className='ti ti-user ti-xs me-1'></span>
                {this.getChoice("User", item.responsible_id) || '---' }
              </span>}

              {item.bps_project && <span className='me-3'>
                <span className='ti ti-briefcase ti-xs me-1'></span>
                {item.bps_project} 
              </span>}

              {item.scope && <span className='me-3'>
                <span className='ti ti-shape ti-xs me-1'></span>
                {item.scope} 
              </span>}

            </div>

            <div className='py-2'>
              {item.message}
            </div>

            <div>
              <Link to={`/log/${item.id}/`}>
                <i className="fa fa-link"></i> {item.place}
              </Link>
            </div>
            { item.request_url !== "http://localhost/" && <div>
              <i className="fa fa-external-link"></i> 
              ({item.request_method}) {item.request_url}
            </div>}
          </div>
        </div>
      )
    }

    renderContent() {
        let that = this

        // Мета-данные проекта не пришли
        if (!this.props.project_title) {
            this.initMeta()
            return null
        }

        // Пока список с объектами не пришел, выходим и ждем его
        if (that.props.list_items === undefined) {
            that.updateItems(that.props.list_page)
            return null
        }

        if(this.props.list_items.length === 0) {
            return <div className="card mb-3">
              <div className="card-body p-3">
                Данные не найдены. Уточните условия отбора и поиска.
              </div>
            </div>
        }

        return [
          <div className="card mb-3">
            <div className="card-body p-2">
              {this.props.perms.includes("batch_actions") &&
                  <span className="btn btn-outline-success waves-effect p-2 me-2" title="Выполнена" onClick={() => {
                      that.setDocumentAction('archive')
                  }}>
                      <span className='ti ti-xs ti-check me-1'></span> 
                      Все сделал
                  </span>
              }

              {this.props.perms.includes("batch_actions") &&
                  <span className="btn btn-outline-secondary waves-effect p-2 me-2" title="Игнорировать" onClick={() => {
                      that.setDocumentAction('ignore')
                  }}>
                      <span className='ti ti-xs ti-eye-off me-1'></span> 
                      В игнор
                  </span>
              }

              {this.props.perms.includes("batch_actions") &&
                  <span className="btn btn-outline-primary waves-effect p-2 me-2" title="Игнорировать" onClick={() => {
                      that.setDocumentAction('to_self')
                  }}>
                      <span className='ti ti-xs ti-bug me-1'></span> 
                      Взять себе
                  </span>
              }
            </div>
          </div>,

          this.props.list_items.map(that.renderDocument),
          
          <Paginator current_page={this.props.list_page}
                      count_pages={this.props.list_count_pages}
                      setPage={this.updateItems}/>
        ]
    }

}

export default wrapView(ListView, (state) => {
    return stateToProps(state, 'log')
})