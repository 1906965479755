import { createReducer } from '@reduxjs/toolkit'

export const reducer = createReducer(
    {
        panel_items: undefined,
    },
    (builder) => {
        builder

            .addCase('lib_panel_items', (state, action) => {
                state.panel_items = action.data
                return state
            })

            .addCase('auth_clean', (state, action) => {
                state.panel_items = undefined
                return state
            })

            .addDefaultCase((state, action) => {
                return state
            })
    }
)
