/* global toastr */

import {store} from '../../index'
import {request, lock} from '../lib/utils'

/**
 * Загрузить опции choice и сохранить их в redux
 * Опции запрашиваются все, без контекста
 */
export function load_choice(name) {
    const choices_in_progress = `choices_progress:${name}`

    // Кто-то другой запрашивает эти опции,
    // ждите пока отработает асинхронный запрос опций
    // Как отработает, все получат свои опции через props-ы
    if(lock.has(choices_in_progress)) {
        return
    }

    lock.set(choices_in_progress)

    request({
        method: 'post',
        url: '/api/choice/items',
        data: {
            name: name,
            context: {},
        },
        success: (data) => {
            store.dispatch({
                type: 'choice_set',
                data: {
                    name: name,
                    items: data,
                },
            })
            lock.remove(choices_in_progress)
        },
        error: (data) => {
            toastr.error(data)
            lock.remove(choices_in_progress)
        },
    })
}
