
import React from 'react'
import ReactDOM from 'react-dom/client'
// import { HashRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
// import { Route, Routes } from 'react-router'
import {createHashRouter, createRoutesFromElements, Route, RouterProvider} from "react-router-dom"
import { configureStore } from '@reduxjs/toolkit'

import IndexView from './index_view'

import { reducer as libReducer } from './smart_bps/lib'
import { reducer as choiceReducer } from './smart_bps/choice'

import { reducer as authReducer } from './auth'
import { reducer as logReducer } from './log'
import { reducer as adminReducer } from './admin'

import { routes as authRoutes } from './auth'
import { routes as logRoutes } from './log'
import { routes as adminRoutes } from './admin'


export const store = configureStore({
    reducer: {
        lib: libReducer,
        choice: choiceReducer,
        auth: authReducer,
        admin: adminReducer,
        log: logReducer,
    },
})

const router = createHashRouter(
  createRoutesFromElements(
      <Route path="/">
          <Route index element={<IndexView />} />
          {authRoutes}
          {adminRoutes}
          {logRoutes}
      </Route>
  )
);

ReactDOM.createRoot(document.getElementById("react-root")).render(
  <React.StrictMode>
      <Provider store={store}>
          <RouterProvider router={router} />
      </Provider>
  </React.StrictMode>
);