import { BaseModelView } from '../smart_bps/bps/model_view'
import { stateToProps } from '../smart_bps/bps/base_view'
import { wrapView } from '../smart_bps/lib/utils'


class ModelView extends BaseModelView {

    renderChoiceToTable(items) {
        if(!items) {
          return null
        }

        return (
          <table className='table'>
            <tbody>
              {Object.entries(items).map((item) => {
                return <tr key={item[0]}>
                  <td nowrap="true">{item[0]}</td>
                  <td>
                    <div style={{width: '600px'}} className='overflow-break-word'>
                      {JSON.stringify(item[1])}
                    </div>
                  </td>
                </tr>
              })}
            </tbody>
          </table>
        )
    }

    renderFormGroup(item, idx) {
      const that = this
      let group_title = item[0]

      // Эту группу рендерим кастомно
      if(group_title === "Подробности") {
        function checkObject(item) {
            return item && Object.keys(item).length > 0
        }

        function objLen(item) {
          return item && Object.keys(item).length
        }
        
        const data = this.state.form_data
        
        let get_content
        if(checkObject(data.request_get_args)) {
          const show_get = this.state.show_get || false
          get_content = [
          <button type="button" className="btn btn-xs btn-secondary waves-effect me-1" 
            onClick={() => {that.setState({show_get: !show_get})}}>
            GET ({objLen(data.request_get_args)})
          </button>,
          show_get && this.renderChoiceToTable(data.request_get_args),
          ]
        }

        let post_content
        if(checkObject(data.request_post_args)) {
          const show_post = this.state.show_post || false
          post_content = [
          <button type="button" className="btn btn-xs btn-secondary waves-effect me-1" 
            onClick={() => {that.setState({show_post: !show_post})}}>
            POST ({objLen(data.request_post_args)})
          </button>,
          show_post && this.renderChoiceToTable(data.request_post_args),
          ]
        }

        let data_content
        if(checkObject(data.data)) {
          const show_data = this.state.show_data || false
          data_content = [
          <button type="button" className="btn btn-xs btn-secondary waves-effect me-1" 
            onClick={() => {that.setState({show_data: !show_data})}}>
            Data ({objLen(data.data)})
          </button>,
          show_data && this.renderChoiceToTable(data.data),
          ]
        }

        let exception_content
        if(data.exception) {
          const show_exception = this.state.show_exception || false
          exception_content = [
            <button className="btn btn-xs btn-secondary me-1" onClick={() => {that.setState({show_exception: !show_exception})}}>
              Exception
            </button>,
            show_exception && <pre>{data.exception}</pre>
          ]
        }
        
        let stack_content
        if(data.stack) {
          const show_stack = this.state.show_stack || false
          stack_content = [
            <button className="btn btn-xs btn-outline-secondary me-1" onClick={() => {that.setState({show_stack: !show_stack})}}>
              Лог
            </button>,
            show_stack && <pre className='py-3'>{data.stack}</pre>
          ]
        }

        return (
          <div className="card mb-3" key={idx}>
            <div className="card-body p-3">
              <h5 className="card-title">{group_title}</h5>
              <div className="card-text">
                
                <div className="mb-3">{data.message}</div>
                  
                { data.request_url && <div>
                  <a href={data.request_url}>
                    <i className="fa fa-external-link"></i> {" "}
                    ({data.request_method}) {data.request_url}
                  </a>
                  { data.request_user_title && <div>{data.request_user_title} <a href={`mailto:${data.request_user_email}`}>{data.request_user_email}</a></div>}
                  
                </div>}
                {get_content}
                {post_content}
                {data_content}
                {exception_content}
                {stack_content} 
              </div>
            </div>
          </div>
        )
      }

      return super.renderFormGroup(item, idx)
    }
}


export default wrapView(ModelView, (state) => {
    return stateToProps(state, 'log')
})
