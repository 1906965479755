/* global toastr */

import { BaseView, baseStateToProps } from '../lib/base_view'

import classNames from 'classnames'
import { connect } from 'react-redux'
import { Link, Navigate } from 'react-router-dom'
import AttrInput from '../lib/attr_input'
import { store } from '../../index'
import React from 'react'
import { request } from '../lib/utils'


export class LoginView extends BaseView {
    constructor(props) {
      super(props)
      this.auth_required = false
      this.formChange = this.formChange.bind(this)
      this.onSubmit = this.onSubmit.bind(this)

      this.state = {
        form_data: {},
        form_valid: null,
      }
    }

    formChange(name, value) {
      var form_data = this.state.form_data
      form_data[name] = value

      this.setState({
        form_data: form_data,
        form_valid: form_data.username && form_data.password,
      })
    }

    onSubmit() {
      request({
        method: 'post',
        url: '/api/auth/token',
        requestType: "form",
        data: new URLSearchParams(this.state.form_data),
        success: (data) => {
          localStorage.setItem('auth_access_token', data.access_token)
          localStorage.setItem('auth_refresh_token', data.refresh_token)
          localStorage.setItem('auth_refresh_url', data.refresh_url)

          store.dispatch({ type: 'auth_authenticated', data })
        },
        error: (data) => {
          toastr.error(data.detail)
        },
      })
    }

    render() {
        if (this.authorized) {
          return <Navigate to="/" replace={true} state={null} />
        }

        const form_data = this.state.form_data

        return (
          <div className="container-xxl">
            <div className="authentication-wrapper authentication-basic container-p-y">
              <div className="authentication-inner py-4">
                <div className="card">
                  <div className="card-body">
                    <div className="app-brand justify-content-center mb-4 mt-2">
                      <a href="index.html" className="app-brand-link gap-2">
                        <span className="app-brand-logo demo">
                        <i className="ti ti-planet"></i>
                          </span>
                        <span className="app-brand-text demo text-body fw-bold ms-1">Добро пожаловать</span>
                      </a>
                    </div>
                    <p className="mb-4">Укажите email и пароль для входа в систему</p>
                      <div className="mb-3 fv-plugins-icon-container">
                        <label for="email" className="form-label">Email</label>
                        <AttrInput data_type="string" name="username" placeholder="Email" onChange={this.formChange} value={form_data.username}/>
                      </div>
                      <div className="mb-3 form-password-toggle fv-plugins-icon-container">
                        <label className="form-label" for="password">Пароль</label>
                        <AttrInput data_type="password" name="password" placeholder="Пароль" onChange={this.formChange} value={form_data.password}/>
                      </div>
                      <div className="mb-3">
                        <button className="btn btn-primary d-grid w-100 waves-effect waves-light" onClick={this.onSubmit}>
                          Вперед
                        </button>
                      </div>
                    <div className="divider my-2">
                      <div className="divider-text">или</div>
                    </div>
                    <div className="d-flex justify-content-center">
                      <Link className="me-2" to="/">Домой</Link> | <Link className="ms-2" to="/auth/restore_password/">Напомнить пароль</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
    }
}

export default connect(baseStateToProps)(LoginView)
