/* global toastr    */

import React from 'react'
import { BaseView, baseStateToProps } from '../lib/base_view'
import { Link, Navigate } from 'react-router-dom'
import classNames from 'classnames'
import { connect } from 'react-redux'

import AttrInput from '../lib/attr_input'
import { request, wrapView } from '../lib/utils'


class PasswordResetCompleteView extends BaseView {
    constructor(props) {
        super(props)

        this.auth_required = false

        this.formChange = this.formChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)

        this.state = {
            form_data: {},
            form_valid: null,
            restore_success: false,
        }
    }

    get token() {
        return this.props.router_params.token
    }

    onSubmit() {
        if(this.state.form_valid === true) {
            const that = this
            const form_data = this.state.form_data
            request({
                method: 'post',
                url: '/api/auth/restore_password_complete',
                data: {
                    email_token: this.token,
                    password: form_data.password,
                },
                success: (data) => {
                    that.setState({restore_success: true })
                    toastr.success("Ваш пароль восстановлен")
                }
            })
        }
    }

    formChange(name, value) {
        var form_data = {...this.state.form_data, [name]: value}

        let form_valid = true
        if(form_data.password == undefined || form_data.password_repeat == undefined) {
            form_valid = false
        }
        else if(form_data.password !== form_data.password_repeat) {
            form_valid = false
        }

        this.setState({
            form_data: form_data,
            form_valid: form_valid,
        })
    }


    render() {
        if (this.state.restore_success) {
            return <Navigate to="/" replace={true} state={null} />
        }

        const btn_class = classNames('btn btn-primary block full-width m-b', {
            disabled: this.state.form_valid === false,
        })

        const form_data = this.state.form_data
        
        return (
            <div className="container-xxl">
              <div className="authentication-wrapper authentication-basic container-p-y">
                <div className="authentication-inner py-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="app-brand justify-content-center mb-4 mt-2">
                        <a href="index.html" className="app-brand-link gap-2">
                          <span className="app-brand-logo demo">
                          <i className="ti ti-planet"></i>
                            </span>
                          <span className="app-brand-text demo text-body fw-bold ms-1">Добро пожаловать</span>
                        </a>
                      </div>
                      <p className="mb-4">
                        Прошу указать ваш новый пароль 2 раза <br/>
                        для продолжения работы
                      </p>
                        <div className="mb-3 fv-plugins-icon-container">
                          <label for="email" className="form-label">Пароль</label>
                          <AttrInput
                                data_type="password"
                                name="password"
                                placeholder="Пароль"
                                onChange={this.formChange}
                                value={form_data.password}
                            />
                        </div>
                        <div className="mb-3 fv-plugins-icon-container">
                          <label for="email" className="form-label">Повтор пароля</label>
                          <AttrInput
                                data_type="password"
                                name="password_repeat"
                                placeholder="Повтор пароля"
                                onChange={this.formChange}
                                value={form_data.password_repeat}
                            />
                        </div>
                        <div className="mb-3">
                          <button className="btn btn-primary d-grid w-100 waves-effect waves-light" onClick={this.onSubmit}>
                            Вперед
                          </button>
                        </div>
                      <div className="divider my-2">
                        <div className="divider-text">или</div>
                      </div>
                      <div className="d-flex justify-content-center">
                        <Link to="/">Домой</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
    }
}

export default wrapView(PasswordResetCompleteView, baseStateToProps)
