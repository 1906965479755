/* global toastr */

import { baseStateToProps, BaseView } from '../lib/base_view'
import { request } from '../lib/utils'
import { store } from '../../index'
import { connect } from 'react-redux'
import React from 'react'
import { Link } from 'react-router-dom'


export class AdminMenuView extends BaseView {
    renderBreadcrumbs() {
      return (
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Домой</Link>
            </li>
            <li className="breadcrumb-item active">Админка</li>
          </ol>
        </nav>
      )
    }

    renderContent() {
        if (this.props.menu === undefined) {
            request({
                method: 'get',
                url: '/api/admin/menu',
                success: (data) => {
                    store.dispatch({ type: 'admin_menu_data', data })
                },
                error: (data) => {
                    toastr(data)
                },
            })
            return null
        }

        let cards = []

        if (this.props.menu !== undefined) {
          this.props.menu.forEach((group) => {
            cards.push(
              <div className="col-md-4" key={group[0]}>
                <div className="card mb-4">
                  <div className="card-body p-3">
                    <h5 className="card-title">{group[0]}</h5>
                    <div className="card-text">
                      {group[1].map((item) => {
                        return (
                          <div key={item.model_name}>
                            <Link to={`/admin/${item.model_name}`} key={item.model_name}>
                              {item.title}{' '}
                            </Link>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        }
        return <div className="row">{cards}</div>
    }
}

function adminStateToProps(state) {
    const props = baseStateToProps(state)

    props.menu = state.admin.menu

    return props
}

export default connect(adminStateToProps)(AdminMenuView)
