export const initialState = {
    menu: undefined,

    // Мета-данные текущей модели
    model_name: undefined,
    model_title: undefined,
    table_headers: undefined,
    search_form: undefined,
    search_presets: undefined,
    form_schema: undefined,
    attrs: undefined,
    perms: undefined,

    // Данные о состоянии поиска
    search_data: undefined,
    search_preset: undefined,

    // Элементов на текущей странице
    list_items: undefined,
    list_items_count: undefined,
    list_page: 1,
    list_count_pages: undefined,
    list_has_next_page: undefined,
}

export function builderCallback(builder) {
    builder

        // Сохранить данные меню админки
        .addCase('admin_menu_data', (state, action) => {
            state.menu = action.data
            return state
        })

        // Информация по конфигурации модели,
        // которая редактируется в текущий момент
        .addCase('admin_model_meta', (state, action) => {
            let data = action.data
            state.model_name = data.model_name
            state.model_title = data.title
            state.table_headers = data.table_headers
            state.search_form = data.search_form
            state.search_presets = data.search_presets
            state.form_schema = data.form_schema
            state.attrs = data.attrs
            state.perms = data.perms
            state.search_data = undefined
            state.search_preset = undefined
            state.list_items = undefined
            return state
        })

        // Сохраняем данные страницы списка модели
        .addCase('admin_list_change', (state, action) => {
            state.list_items = action.data.items
            state.list_page = action.data.page
            state.list_has_next_page = action.data.has_next_page
            state.list_items_count = action.data.items_count
            state.list_count_pages = action.data.count_pages
            return state
        })

        // Сохраняем данные формы поиска и сбрасываем данные о карточках на странице
        .addCase('admin_search', (state, action) => {
            state.search_data = action.data
            state.list_items = undefined
            state.list_page = 1
            return state
        })

        // Очистка данных на странице (из-за изменений в объекте), чтобы запросить список заново
        .addCase('admin_list_clean', (state, action) => {
            state.list_items = undefined
            return state
        })

        .addDefaultCase((state, action) => {
            return state
        })
}
