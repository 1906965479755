/* global toastr $ */

import AttrInput from '../lib/attr_input'
import { BaseBpsView } from './base_view'
import { request } from '../lib/utils'

import { Link } from 'react-router-dom'
import { split_list, format_datetime } from '../lib/utils'
import { store } from '../../index'
import { Component } from 'react'
import { FileListBlock } from './files'




/**
 * Отображение списка переходов с этапа на этап
 * props:
 * - event_access
 * - project_name
 * - object_id
 * - state
 * - documentToState
 */
export class EventAccess extends Component {
  constructor(props) {
    super(props)

    this.renderEventModal = this.renderEventModal.bind(this)
    this.selectEvent = this.selectEvent.bind(this)
    this.renderEventsInfo = this.renderEventsInfo.bind(this)
    this.renderAllowedGroups = this.renderAllowedGroups.bind(this)
    this.addEvent = this.addEvent.bind(this)
  }

  get allowed_groups() {
    let res = {}

    this.props.event_access.forEach(item => {
      if (item.warnings.length == 0) {
        let group_events = res[item.group]
        if (group_events === undefined) {
          group_events = []
          res[item.group] = group_events
        }
        group_events.push(item)
      }
    });
    return res
  }

  get disabled_events() {
    let res = []
    this.props.event_access.forEach(item => {
      if (item.warnings.length > 0) {
        res.push(item)
      }
    });
    return res
  }

  processChangeEvents() {
    this.allowed_groups = {}  // Группы активных переходов
    this.disabled_events = [];  // Недоступные переходы

    // Рассортируем события по недоступным и доступным (с группами)
    this.props.event_access.forEach(item => {
      if (item.warnings.length > 0) {
        this.disabled_events.push(item)
      }
      else {
        let group_events = this.allowed_groups[item.group]
        if (group_events === undefined) {
          group_events = []
          this.allowed_groups[item.group] = group_events
        }
        group_events.push(item)
      }
    });
  }

  selectEvent(event_name) {
    const that = this

    request({
      method: 'post',
      url: `/api/${this.props.project_name}/${this.props.object_id}/get_event_meta`,
      data: {
        state_from: this.props.state,
        event_name: event_name,
      },
      success: (data) => {
        that.setState({ selected_event: data }, () => {
          $('#modal_event').modal("show")
        })
      }
    })
  }

  addEvent() {
    const that = this
    const event = this.state.selected_event

    request({
      method: 'post',
      url: `/api/${this.props.project_name}/${this.props.object_id}/add_event`,
      data: {
        state_from: this.props.state,
        event_name: event.name,
        responsible_id: this.state.selected_event_responsible_id,
        message: this.state.selected_event_message,
      },
      success: (data) => {
        $('#modal_event').modal("hide")

        // Отправить новое состояние после перехода в документ
        that.props.documentToState(data, () => {
          that.setState({
            selected_event: undefined,
            selected_event_responsible_id: undefined,
            selected_event_message: undefined,
          })
        })
      },
      error: (data) => {
        toastr.error(data.detail)
        $('#modal_event').modal("hide")
      }
    })
  }

  renderEventsInfo() {
    if (this.disabled_events.length === 0) {
      return null
    }

    return [
      <button key="button"
        type="button"
        className="btn btn-link p-2 me-2"
        data-toggle="modal"
        data-target="#modal_disabled_events">
        Другие
      </button>,

      <div key="disabled_events" className="modal fade" role="dialog" id="modal_disabled_events">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
              <h4 className="modal-title text-center">Недоступные действия по документу</h4>
            </div>
            <div className="modal-body p-xs">
              {this.disabled_events.map(function (event) {
                return (
                  <div key={event.name} className="m-b-sm">
                    <span className="badge">{event.group}: {event.title}</span>
                    <div>{event.warnings}</div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    ]
  }

  renderEventModal() {
    if (this.state === null || this.state.selected_event === undefined) {
      return null;
    }

    const event = this.state.selected_event;
    const that = this

    return (
      <div className="modal" id="modal_event" tabIndex="-1" style={{ display: "none" }} aria-hidden="true" key="selected_event">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel1">{event.title}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <AttrInput
                data_type="text"
                rows="5"
                name="selected_event_message"
                value={this.state.selected_event_message}
                access="edit"
                onChange={(name, value) => { that.setState({ [name]: value }) }}
              />
              {
                event.change_responsible === "user" &&
                <div className="mt-2">
                  <AttrInput
                    data_type="integer"
                    name="selected_event_responsible_id"
                    value={this.state.selected_event_responsible_id}
                    choice_items={event.user_choices}
                    access="edit"
                    onChange={(name, value) => { that.setState({ [name]: value }) }}
                  />
                </div>
              }
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary p-2 me-1" onClick={this.addEvent}>Отправить</button>
              <button type="button" className="btn btn-secondary p-2 me-1" data-bs-dismiss="modal">Закрыть</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderAllowedGroups() {
    if (Object.keys(this.allowed_groups).length == 0) {
      return null
    }

    const that = this

    return Object.entries(this.allowed_groups).map(entry => {
      const gr_title = entry[0]
      const gr_events = entry[1]

      return (
        <div className="btn-group me-2" key={gr_title}>
          <button type="button" className="btn btn-outline-success dropdown-toggle waves-effect p-2" data-bs-toggle="dropdown" aria-expanded="false">
            <span className="ti ti-send me-1"></span>
            {gr_title}
          </button>
          <ul className="dropdown-menu">
            {gr_events.map(function (event) {
              return (<li key={event.name}>
                <a className="dropdown-item" onClick={() => { that.selectEvent(event.name) }}>{event.title}</a>
              </li>)
            })}
          </ul>
        </div>
      )
    })
  }

  render() {
    return [
      this.renderAllowedGroups(),
      this.renderEventsInfo(),
      this.renderEventModal(),
    ]
  }
}


export class BaseModelView extends BaseBpsView {

  constructor(props) {
    super(props)

    this.state = {}

    this.formChange = this.formChange.bind(this)
    this.onSearch = this.onSearch.bind(this)
    this.saveObject = this.saveObject.bind(this)
    this.deleteObject = this.deleteObject.bind(this)
    this.renderEventBlock = this.renderEventBlock.bind(this)
    this.documentToState = this.documentToState.bind(this)
    this.fileUploaded = this.fileUploaded.bind(this)
    this.getDocument = this.getDocument.bind(this)
  }

  get object_id() {
    const object_id = this.props.router_params.object_id
    return object_id === "new" ? 0 : parseInt(object_id)
  }

  onSearch(data) {
    store.dispatch({ type: `${this.project_name}_search`, data })
    this.redirect_to(`/${this.project_name}`)
  }

  formChange(name, value) {
    this.setState({ form_data: { ...this.state.form_data, [name]: value } });
  }

  getDocument() {
    if (this.getDocumentWork === true) {
      return null  // Подождем пока данные документа загружаются
    }

    this.getDocumentWork = true
    const that = this

    request({
      method: 'get',
      url: `/api/${this.project_name}/${this.object_id}/get_object`,
      success: (data) => {
        that.documentToState(data, () => {
          that.getDocumentWork = undefined

          if (this.object_id === 0 && data.id !== 0) {
            that.redirect_to(`/${this.project_name}/${data.id}`)
          }
        })
      },
      error: (data) => {
        that.getDocumentWork = undefined
        toastr.error("Ошибка в получении данных")
      }
    })
  }

  documentToState(data, cb) {
    let state_data = { ...data }

    state_data.form_data_initial = data.form_data
    state_data.object_id = data.id
    state_data.id = undefined

    // Если прилетели в обновлении другой id документа чем в адресе
    // переходим в этот документ
    if (this.object_id !== data.id) {
      state_data.redirect_to = `/${this.project_name}/${data.id}`
    }

    this.setState(state_data, cb)
  }

  saveObject() {
    const that = this

    request({
      method: 'post',
      url: `/api/${that.project_name}/${that.object_id}/update_object`,
      data: {
        "new_data": that.state.form_data,
        "old_data": that.state.form_data_initial,
      },
      success: (data) => {
        that.documentToState(data, () => {
          store.dispatch({ type: `${that.project_name}_list_clean` })
          toastr.success("Изменения сохранены")
        })
      }
    })
  }

  deleteObject() {
    const that = this

    request({
      method: 'delete',
      url: `/api/${that.project_name}/${this.object_id}/delete_object`,
      success: (data) => {
        store.dispatch({ type: `${that.project_name}_list_clean` })
        that.redirect_to(`/${that.project_name}`)
        toastr.success(`Карточка ${that.state.title} удалена`)
      },
      error: (data) => {
        toastr.error(data.detail, "Ошибка удаления")
      }
    })
  }

  fileUploaded(file) {
    this.setState({ files: [...this.state.files, file] })
  }

  renderBreadcrumbs() {
    return (
      <nav aria-label="breadcrumb" key="breadcrumb">
        <ol className="breadcrumb breadcrumb-style">
          <li className="breadcrumb-item">
            <Link to="/">Домой</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/${this.project_name}`}>{this.props.project_title}</Link>
          </li>
          <li className="breadcrumb-item active">{this.state.title}</li>
        </ol>
      </nav>
    )
  }

  renderContentLeftAction() {
    return (
      <div className="card mb-3" key="actions">
        <div className="card-body p-2">
          <span className="btn btn-outline-primary waves-effect p-2 me-2" onClick={this.saveObject}>
            <span className="ti ti-xs ti-check me-1"></span>
            Сохранить
          </span>
          <EventAccess
            event_access={this.state.event_access}
            project_name={this.project_name}
            object_id={this.object_id}
            state={this.state.state}
            documentToState={this.documentToState}
          />
          {this.state.perms.includes("delete") && <button className="btn btn-outline-danger waves-effect p-2 me-2" onClick={this.deleteObject}>
            <span className="ti ti-xs ti-trash me-1"></span>
            Удалить</button>}
        </div>
      </div>
    )
  }

  renderFormAttr(attr, horizontal) {
    const that = this
    const data = this.state.form_data
    const form_access = this.state.form_access
    const attr_messages = this.state.form_messages[attr.name]

    return <AttrInput key={attr.name}
      data_type={attr.data_type}
      choice_name={attr.choice_name}
      choices={that.props.choices}
      name={attr.name}
      title={attr.title}
      value={data[attr.name]}
      access={form_access[attr.name]}
      messages={attr_messages}
      inner_attrs={attr.inner_attrs}
      horizontal={horizontal || false}
      onChange={that.formChange} />
  }

  renderFormGroup(item, idx) {
    const that = this
    const form_access = this.state.form_access
    const attrs = this.props.attrs
    const form_access_names = Object.keys(form_access)

    const group_title = item[0]
    const attr_names = item[1]
    const attr_names_allowed = attr_names.filter(value => form_access_names.includes(value))

    // В этой группе нет полей, для которых есть доступ
    if (attr_names_allowed.length === 0) {
      return null
    }

    let wide_attrs = []
    let short_attrs = []

    attr_names_allowed.forEach(attr_name => {
      const attr = attrs[attr_name]

      if (attr.data_type === "text" || attr.data_type === "table" || attr.tags.includes("form_wide"))
        wide_attrs.push(attr)
      else
        short_attrs.push(attr)
    });

    return (
      <div className="card mb-3" key={idx+100}>
        <div className="card-body p-3">
          <h5 className="card-title">{group_title}</h5>
          <div className="card-text">
            <div className="row form-sm">
              {short_attrs.length > 0 && split_list(short_attrs, 2).map((attrs, idx) => {
                return (
                  <div className="col-md-6" key={idx}>
                    {attrs.map(attr => { return that.renderFormAttr(attr, true) })}
                  </div>
                )
              })}
              {wide_attrs.length > 0 && <div className="col-md-12" key={idx}>
                {wide_attrs.map(attr => { return that.renderFormAttr(attr, false) })}
              </div>}
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderContentLeft() {
    const that = this;

    return [
      this.renderContentLeftAction(),

      this.props.form_schema.map((item, idx) => {
        return that.renderFormGroup(item, idx)
      }),

      this.renderEventBlock(),
    ]
  }

  renderInfo() {
    return (
      <div className="card mb-3">
        <div className="card-body p-3">
          <h5 className="card-title">Основное</h5>
          <div className="card-text">
            Ответственный: {this.getChoice("User", this.state.responsible_id) || '---'} <br />
            Этап: {this.getChoice(`bps_${this.project_name}_state`, this.state.state) || '---'} <br />
          </div>
        </div>
      </div>
    )
  }

  renderComments() {
    const that = this
    const perms = this.state.perms

    function AddComment() {
      request({
        method: 'post',
        url: `/api/${that.project_name}/${that.object_id}/add_comment`,
        data: { message: that.state.comment_form_message },
        success: (data) => {
          that.setState({
            comment_form_message: "",
            comments: that.state.comments.concat([data])
          })
          toastr.success("Комментарий добавлен")
        }
      })
    }

    return (
      <div className="card mb-3">
        <div className="card-body p-3">
          <h5 className="card-title">
            Комментарии <span className="badge">{that.state.comments.length}</span>
          </h5>
          <div className="card-text">
            {that.state.comments.map((item) => {
              return <div key={item.id}>
                <span className="text-muted">
                  {/* {format_datetime(item.d_create)} */}
                  {this.getChoice("User", item.author_id)}:
                </span>{" "}
                {item.message}
              </div>
            })}

            {perms.includes("add_comment") && <div className='m-t-sm'>
              <div className="mb-1">
                <AttrInput
                  data_type="text"
                  name="comment"
                  value={this.state.comment_form_message || ""}
                  access="edit"
                  onChange={(name, value) => { that.setState({ comment_form_message: value }) }}
                />
              </div>
              <span className="btn btn-secondary waves-effect btn-xs" onClick={AddComment}>
                <i className="ti ti-send ti-xs me-1"></i> Отправить
              </span>
            </div>}
          </div>
        </div>
      </div>
    )
  }

  renderEventBlock() {
    return (
      <div className="card mb-3" key="events">
        <div className="card-body p-3">
          <h5 className="card-title">
            События <span className="badge">{this.state.events.length}</span>
          </h5>
          <div className="card-text">
            <table className="table table-condensed table-bordered">
              <thead>
                <tr>
                  <th>Создано</th>
                  <th>Событие</th>
                  <th>Ответственный</th>
                </tr>
              </thead>
              <tbody>
                <tr key="head">
                  <td>
                    {format_datetime(this.state.d_create)} {" "}
                    <span className="text-muted">{this.getChoice("User", this.state.author_id)}</span>
                  </td>
                  <td>Создание</td>
                  <td>---</td>
                </tr>
                {this.state.events.length > 0 && this.state.events.map((event, idx) => {
                  return <tr key={idx}>
                    <td>
                      {format_datetime(event.d_create)} {" "}
                      <span className="text-muted">{this.getChoice("User", event.author_id)}</span>
                    </td>
                    <td>
                      {event.title}: <span className="text-muted">{event.message}</span>
                    </td>
                    <td>
                      <span className="text-muted">{this.getChoice("User", event.responsible_id)}</span>
                    </td>
                  </tr>
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }

  waitRenderReady() {
    // Базовое
    const dummyContent = super.waitRenderReady()
    if (dummyContent) {
      return dummyContent
    }

    // Ждем поступления метаданных этой модели
    if (!this.props.project_title) {
      this.initMeta()
      return null
    }

    // Ждем данных этого объекта
    if (this.state.object_id !== this.object_id) {
      this.getDocument()
      return null
    }
  }

  renderContent() {
    return <div className="row" key="content">
      <div className="col-lg-8">
        {this.renderContentLeft()}
      </div>
      <div className="col-lg-4">
        {this.renderInfo()}

        <FileListBlock
          project_name={this.project_name}
          object_id={this.object_id}
          files_schema={this.state.files_schema}
          files={this.state.files}
          fileUploaded={this.fileUploaded}
        />

        {this.renderComments()}
      </div>
    </div>
  }
}
