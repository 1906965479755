/* global toastr */

import { BaseView, baseStateToProps } from '../lib/base_view'

import classNames from 'classnames'
import { connect } from 'react-redux'
import { Link, Navigate } from 'react-router-dom'
import AttrInput from '../lib/attr_input'
import { store } from '../../index'
import React from 'react'
import { request } from '../lib/utils'


export class ChangePasswordView extends BaseView {
    constructor(props) {
        super(props)
        this.auth_required = true
        this.formChange = this.formChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)

        this.state = {
            form_data: {},
            form_valid: false,
        }
    }

    formChange(name, value) {
        const form_data = {...this.state.form_data, [name]: value}

        let form_valid = true
        if(form_data.password_current == undefined || form_data.password_new == undefined || form_data.password_new_repeat == undefined) {
            form_valid = false
        }
        else if(form_data.password_new !== form_data.password_new_repeat) {
            form_valid = false
        }

        this.setState({
            form_data: form_data,
            form_valid: form_valid,
        })
    }

    onSubmit() {
        const form_data = this.state.form_data

        if(this.state.form_valid === true) {
            request({
                method: 'post',
                url: '/api/auth/change_password',
                data: {
                    password_current: form_data.password_current,
                    password_new: form_data.password_new,
                },
                success: (data) => {
                    if(data !== null && data.warning) {
                        toastr.warning(data.warning)
                    }
                    else {
                        toastr.success("Пароль успешно изменен")
                    }
                },
            })
        }
    }

    render() {
        if (!this.authorized) {
            return <Navigate to="/" replace={true} state={null} />
        }

        const btn_class = classNames('btn btn-primary block full-width m-b', {
            disabled: this.state.form_valid === false,
        })

        const form_data = this.state.form_data

        return (
            <div className="container-xxl">
              <div className="authentication-wrapper authentication-basic container-p-y">
                <div className="authentication-inner py-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="app-brand justify-content-center mb-4 mt-2">
                        <a href="index.html" className="app-brand-link gap-2">
                          <span className="app-brand-logo demo">
                          <i className="ti ti-planet"></i>
                            </span>
                          <span className="app-brand-text demo text-body fw-bold ms-1">Поменять пароль</span>
                        </a>
                      </div>
                      <p className="mb-4">
                        
                      </p>
                        <div className="mb-3 fv-plugins-icon-container">
                          <label for="email" className="form-label">Текущий пароль</label>
                          <AttrInput
                                    data_type="password"
                                    name="password_current"
                                    placeholder="Текущий пароль"
                                    onChange={this.formChange}
                                    value={form_data.password_current || ""}
                                />
                        </div>
                        <div className="mb-3 fv-plugins-icon-container">
                          <label for="email" className="form-label">Новый пароль</label>
                          <AttrInput
                                    data_type="password"
                                    name="password_new"
                                    placeholder="Новый пароль"
                                    onChange={this.formChange}
                                    value={form_data.password_new || ""}
                                />
                        </div>
                        <div className="mb-3 fv-plugins-icon-container">
                          <label for="email" className="form-label">Новый пароль (еще раз)</label>
                          <AttrInput
                                    data_type="password"
                                    name="password_new_repeat"
                                    placeholder="Новый пароль (еще раз)"
                                    onChange={this.formChange}
                                    value={form_data.password_new_repeat || ""}
                                />
                        </div>
                        <div className="mb-3">
                          <button className="btn btn-primary d-grid w-100 waves-effect waves-light" onClick={this.onSubmit}>
                          Поменять
                          </button>
                        </div>
                      <div className="divider my-2">
                        <div className="divider-text">или</div>
                      </div>
                      <div className="d-flex justify-content-center">
                        <Link to="/">Домой</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
    }
}

export default connect(baseStateToProps)(ChangePasswordView)
