import { createReducer } from '@reduxjs/toolkit'


export const reducer = createReducer(
    {
        choices: {},
    },
    (builder) => {
        builder.addCase('choice_set', (state, action) => {
            state.choices[action.data.name] = action.data.items
            return state
        }).addDefaultCase((state, action) => {
            return state
        })
    }
)
